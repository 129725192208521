import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PageHeaderComponent, SidenavComponent, SidePanelComponent } from '@shared/layout';

@Component({
  selector: 'app-cpl',
  standalone: true,
  imports: [RouterOutlet, SidePanelComponent, SidenavComponent, PageHeaderComponent],
  templateUrl: './cpl.component.html',
  styleUrl: './cpl.component.scss'
})
export class CplComponent {

}
